import { Button, Container, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AddButton } from '../../components/AddButton';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { LoadingError } from '../../components/LoadingError';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { useRequireLogin } from '../../contexts/RequiresLoginContext';
import { ROUTES } from '../../routes';
import { useAssignments } from './api/useAssignments';
import { AssignmentsList } from './components/AssignmentsList';
import { DemoCard } from './components/DemoCard';
import { useDemoAssignments } from './api/useDemoAssignments';

export function AssignmentsPage() {
  const { data: assignments, isLoading, isError, error } = useAssignments();
  const { data: demoAssignments, isLoading: isDemoLoading } = useDemoAssignments();

  const requireLogin = useRequireLogin();
  const [showDemoCard, setShowDemoCard] = useState(true);

  useEffect(() => {
    const isDemoCardDismissed = localStorage.getItem('demoCardDismissed');
    if (isDemoCardDismissed === 'true') {
      setShowDemoCard(false);
    }
  }, []);

  const handleDismissCard = () => {
    setShowDemoCard(false);
    localStorage.setItem('demoCardDismissed', 'true');
  };

  if (isLoading || isDemoLoading) {
    return <LoadingIndicator />;
  }

  if (isError && error.response?.data?.googleSync) {
    requireLogin();
    return null;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb />
      {assignments.length > 0 ? (
        <>
          {showDemoCard && <DemoCard onDismiss={handleDismissCard} demoAssignments={demoAssignments} />}
          <AssignmentsList assignments={assignments} />
        </>
      ) : (
        <>
          {showDemoCard && <DemoCard onDismiss={handleDismissCard} demoAssignments={demoAssignments} />}
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h5">
              You do not have any assignments yet
              <AddButton to={ROUTES.newClass()} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={ROUTES.newAssignment()}
              sx={{ marginY: 3 }}
            >
              Create your first assignment
            </Button>
          </Paper>
        </>
      )}
      <AddButton path={ROUTES.ASSIGNMENTS_NEW} tooltip="Add New Assignment" />
    </Container>
  );
}
