import { Button, TableBody, TableHead, Tooltip, TableSortLabel } from '@mui/material';
import { useState, useMemo } from 'react';
import { Edit as EditIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { uniq, isEmpty } from 'ramda';
import { format } from 'date-fns';
import { parseDateWithoutTimezone } from '../../../utils/date';
import { ROUTES } from '../../../routes';
import { Table, Td, Th, Tr } from '../../../components/layout/Table';
import { IconButtonWithTooltip } from '../../../components/layout/IconButtonWithTooltip';
import { BooleanValue } from '../../../components/BooleanValue';
import { isOutlineAndWritingAssignment } from '../../../helpers/assignment';
import { shorten } from '../../../utils/text';

export function AssignmentsList({ assignments, caption = 'Your Assignments', ariaLabel = 'List of teacher assignments', sx }) {
  const [orderBy, setOrderBy] = useState('createdAt'); // TODO add sorting for other fields
  const [order, setOrder] = useState('desc');

  // TODO: Relocate sorting logic to table component
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedAssignments = useMemo(() =>
    [...assignments].sort(
      (a, b) => {
        if (order === 'asc') {
          return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1;
        }
        return a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ? -1 : 1;
      },
      [assignments, orderBy, order]
    )
  );

  return (
    <Table ariaLabel={ariaLabel} caption={caption} pagination sx={sx}>
      <TableHead>
        <Tr>
          <Th align="left">
            <TableSortLabel active={orderBy === 'title'} direction={order} onClick={() => handleRequestSort('title')}>
              Title
            </TableSortLabel>
          </Th>
          <Th align="left">
            {/* // TODO:  raises an error */}
            {/* <TableSortLabel
              active={orderBy === 'templateId'}
              direction={order}
              onClick={() => handleRequestSort('templateId')}
            > */}
            Template
            {/* </TableSortLabel> */}
          </Th>
          <Th>Outline</Th>
          <Th>Gwen AI</Th>
          <Th>
            {/* // TODO:  problem with toLowerCase */}
            {/* <TableSortLabel active={orderBy === 'dueDate'} direction={order} onClick={() => handleRequestSort('dueDate')}> */}
            Due date
            {/* </TableSortLabel> */}
          </Th>
          <Th>Assigned To</Th>
          <Th>Actions</Th>
        </Tr>
      </TableHead>
      <TableBody>
        {sortedAssignments.map((assignment) => {
          const assignedToClasses = uniq(
            assignment.userAssignments
              .map((userAssignment) => userAssignment.user.courseUsers)
              .flat()
              .map((courseUser) => courseUser.course.name)
          );
          return (
            <Tr key={assignment.id}>
              <Td>
                <Tooltip title="View Assignment" placement="left">
                  <Button
                    component={Link}
                    fullWidth
                    style={{ cursor: 'pointer', textAlign: 'left' }}
                    sx={{ justifyContent: 'start' }}
                    startIcon={<AssignmentIcon />}
                    to={ROUTES.showAssignment(assignment.id)}
                    variant="text"
                  >
                    {assignment.title}
                  </Button>
                </Tooltip>
              </Td>
              <Td align="left">{assignment.template ? shorten(assignment.template.name, 35) : <BooleanValue value={false} align="left" />}</Td>
              <Td>
                {/* TODO: Deduplicate between two new assignment list components */}
                <BooleanValue value={isOutlineAndWritingAssignment(assignment.type)} />
              </Td>
              <Td>
                <BooleanValue value={assignment.useGwen} />
              </Td>
              <Td>
                <Tooltip
                  title="TODO in the end the due date will be per assignment so we'll merge these two columns"
                  placement="left"
                >
                  {assignment.dueDate ? format(parseDateWithoutTimezone(assignment.dueDate), 'MMM do, yyyy') : <BooleanValue value={false} />}
                </Tooltip>
              </Td>
              <Td style={ isEmpty(assignedToClasses) ? { color: 'lightgrey', fontStyle: 'italic' } : {}}>
                <Tooltip
                  title="The classes listed here does not represent all students in a class. It just means that at least one student from that classes got assigned to given writing assignment"
                  placement="left"
                >
                  {assignedToClasses.length > 0 ? assignedToClasses.join(', ') : 'Unassigned'}{' '}
                </Tooltip>
              </Td>
              <Td>
                {isEmpty(assignedToClasses) && (
                  <IconButtonWithTooltip
                    tooltip="Edit Assignment"
                    icon={<EditIcon />}
                    to={ROUTES.editAssignment(assignment.id)}
                  />
                )}
              </Td>
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}
