import { Navigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, Container } from '@mui/material';
import { useEffect } from 'react';
import { SignUp, RoleSelection } from './components';
import Footer from '../../components/Footer';
import gwenTeacher from '../../assets/gwen-teacher-table.jpg';
import gwenStudent from '../../assets/gwen-student.png';

export function SignUpPage() {
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role');

  // Blokuj scroll na poziomie body
  useEffect(() => {
    // Zapisz oryginalny styl
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Ustaw overflow: hidden na body
    document.body.style.overflow = 'hidden';
    
    // Przywróć oryginalny styl przy odmontowaniu
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  if (!role) {
    return <Navigate to="/sign-up?role=teacher" />;
  }

  if (!['student', 'teacher', 'parent'].includes(role)) {
    return <Navigate to="/sign-up?role=teacher" />;
  }

  const getImageForRole = () => {
    switch(role) {
      case 'teacher':
        return gwenTeacher;
      case 'parent':
        return gwenStudent;
      case 'student':
      default:
        return gwenStudent;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Grid container sx={{ flex: 1, overflow: 'hidden' }}>
          {/* Lewa kolumna */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: { xs: 2, md: 3 },
              overflow: 'auto',
            }}
          >
            {/* Separator po prawej stronie dla większych ekranów */}
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                position: 'absolute',
                right: 0,
                top: '15%',
                bottom: '15%',
                width: '1px',
                bgcolor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
            {/* Separator na dole dla mniejszych ekranów */}
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                position: 'absolute',
                bottom: 0,
                left: '15%',
                right: '15%',
                height: '1px',
                bgcolor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
            <Box sx={{ mb: 0, textAlign: 'center' }}>
              <img
                src={getImageForRole()}
                alt={`${role} profile`}
                style={{
                  height: '190px',
                  objectFit: 'contain',
                }}
              />
            </Box>

            <RoleSelection />
          </Grid>

          {/* Prawa kolumna */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: { xs: 2, md: 3 },
              overflow: 'auto',
            }}
          >
            <SignUp role={role} />
          </Grid>
        </Grid>
      </Container>

      <Footer sx={{ py: 0.5, mt: 'auto', borderTopWidth: '1px' }} />
    </Box>
  );
} 