import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  EditNote as EditNoteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';

import { isEmpty } from 'ramda';
import { ROUTES } from '../../../routes';
import { UserContext } from '../../../contexts/UserContext';
import { UserAssignmentStatus } from '../../../helpers/enums';

const isAssignmentTestable = (assignment) => !isEmpty(assignment.instructions);

export function TestAssignmentMenu({ assignment }) {
  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const user = useContext(UserContext);
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        aria-controls={isOpen ? 'test-assignment-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        variant="outlined"
        disabled={!isAssignmentTestable(assignment)}
        startIcon={<PlayArrowIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        id="test-assignment"
        onClick={handleClick}
        ref={anchorRef}
        sx={{
          borderRadius: 2,
          textTransform: 'none',
          fontWeight: 500,
          px: 2,
          borderColor: 'divider',
          color: 'text.secondary',
          fontSize: '0.875rem',
          letterSpacing: '0.5px',
          '&:hover': {
            borderColor: 'primary.main',
            backgroundColor: 'rgba(25, 118, 210, 0.04)',
          },
          '&.Mui-disabled': {
            color: 'text.disabled',
          },
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          },
          '& .MuiButton-endIcon': {
            marginLeft: '4px',
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          },
        }}
      >
        TEST
      </Button>
      <Menu
        id="test-assignment-menu"
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        onClick={handleClose}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'test-assignment',
        }}
        sx={{
          mt: 1,
          '& .MuiPaper-root': {
            borderRadius: 2,
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)',
            border: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        <MenuItem
          component={Link}
          to={ROUTES.writeAssignment(assignment.id, user.id)}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)',
            },
          }}
        >
          <ListItemIcon>
            <EditNoteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography>Write a test assignment</Typography>
          </ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          disabled={assignment.teacherTestStatus === UserAssignmentStatus.CREATED}
          to={ROUTES.showAssignmentSubmission(assignment.id, user.id)}
          sx={{
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)',
            },
            '&.Mui-disabled': {
              color: 'text.disabled',
            },
          }}
        >
          <ListItemIcon>
            <AssignmentIcon fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography>Check your test work</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
