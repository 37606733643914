import { forwardRef } from 'react';

export const SSOLoginButton = forwardRef(({ label, onClick, Icon, disabled }, ref) => (
  <button className="gsi-material-button" onClick={onClick} type="button" ref={ref} style={{ border: '1px solid rgba(63, 80, 181, 0.5)', width: '28vh' }} disabled={disabled}>
    <div className="gsi-material-button-state" />
    <div className="gsi-material-button-content-wrapper">
      <div className="gsi-material-button-icon">
        <Icon />
      </div>
      <span className="gsi-material-button-contents">{label}</span>
    </div>
  </button>
));

SSOLoginButton.displayName = 'GoogleLoginButton';
