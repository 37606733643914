import { Box, Container, Typography, Link as MuiLink, Avatar, Paper } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { CodeLogin } from './CodeLogin';
import { AppleIcon, EmailIcon, FacebookIcon, GoogleIcon, MicrosoftIcon, SchoolIcon } from './Icons';
import { SSOLoginButton } from './SSOLoginButton';
import { ROUTES } from '../../../routes';

import {
  EDLINK_AUTH_URL,
  FACEBOOK_SIGN_IN_URL,
  GOOGLE_SIGN_IN_URL,
  COGNITO_LOGIN_URL,
  APPLE_SIGN_IN_URL,
  MICROSOFT_SIGN_IN_URL,
} from '../../../config';

const SignIn = ({ role }) => {
  const navigate = useNavigate();
  const [currentRole, setCurrentRole] = useState(role);
  
  // Aktualizuj currentRole tylko gdy zmienia się parametr role w URL
  useEffect(() => {
    setCurrentRole(role);
  }, [role]);

  const googleSignIn = () => window.open(`${GOOGLE_SIGN_IN_URL}/${currentRole}`, '_self');
  const edLinkLogin = () => window.open(EDLINK_AUTH_URL, '_self');
  const facebookSignIn = () => window.open(FACEBOOK_SIGN_IN_URL, '_self');
  const cognitoLogin = () => window.open(COGNITO_LOGIN_URL, '_self');
  const appleSignIn = () => window.open(APPLE_SIGN_IN_URL, '_self');
  const microsoftSignIn = () => window.open(MICROSOFT_SIGN_IN_URL, '_self');

  const handleCreateAccount = (e) => {
    e.preventDefault();
    navigate(`${ROUTES.SIGN_UP}?role=${currentRole}`);
  };

  // Prefiks dla przycisków logowania
  const buttonPrefix = 'Continue with';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
        maxWidth: '90%',
        padding: 3,
      }}
    >
      {/* Nagłówek z gradientowym podkreśleniem */}
      <Box sx={{ mb: 4, width: '100%', textAlign: 'center' }}>
        <Typography 
          variant="h4" 
          component="h1"
          sx={{ 
            fontWeight: 600,
            position: 'relative',
            display: 'inline-block',
            pb: 1,
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '70%',
              height: '3px',
              bottom: 0,
              left: '15%',
              background: 'linear-gradient(90deg, transparent, primary.main, transparent)',
            }
          }}
        >
          Sign In
        </Typography>
      </Box>

      {/* Formularz logowania */}
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2, width: '100%' }}>
        <SSOLoginButton onClick={googleSignIn} label={`${buttonPrefix} Google`} Icon={GoogleIcon} />
        
        <SSOLoginButton label={`${buttonPrefix} Microsoft`} Icon={MicrosoftIcon} onClick={microsoftSignIn} />
        <SSOLoginButton label={`${buttonPrefix} Apple`} Icon={AppleIcon} onClick={appleSignIn} />
        <SSOLoginButton label={`${buttonPrefix} Facebook`} Icon={FacebookIcon} onClick={facebookSignIn} />
        <SSOLoginButton label={`${buttonPrefix} Email`} Icon={EmailIcon} onClick={cognitoLogin} />

        <SSOLoginButton onClick={edLinkLogin} label={`${buttonPrefix} School SSO`} Icon={SchoolIcon} />

        <div style={{ width: '28vh' }}>
          <Box sx={{ 
            textAlign: 'center', 
            paddingBottom: '12px' 
          }}>
            <Typography color="text.secondary">or</Typography>
          </Box>
          <CodeLogin />
        </div>
      </Box>
      
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="text.secondary">
          Don&apos;t have an account?{' '}
          <MuiLink 
            component="a"
            href="#"
            onClick={handleCreateAccount}
            sx={{ 
              fontWeight: 500,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Sign Up
          </MuiLink>
        </Typography>
      </Box>
    </Box>
  );
};

export default SignIn;
