import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Paper } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export function RoleSelection() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentRole = searchParams.get('role') || 'teacher';
  const isCreating = searchParams.get('create') === 'true';

  const handleRadioRoleChange = (event) => {
    if (event.target.value !== 'admin') {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('role', event.target.value);
      setSearchParams(newSearchParams);
    }
  };

  const roles = [
    {
      id: 'teacher',
      label: 'Teacher / Tutor',
      icon: SchoolIcon,
      color: '#1976d2',
      description: 'I will teach and guide students',
      disabled: false,
    },
    {
      id: 'parent',
      label: 'Parent',
      icon: FamilyRestroomIcon,
      color: '#ed6c02',
      description: 'I will support my child\'s learning progress',
      disabled: false,
    },
    {
      id: 'admin',
      label: 'School Administrator',
      icon: AdminPanelSettingsIcon,
      color: '#9c27b0',
      description: 'I will oversee educational programs',
      disabled: true,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
        padding: 2,
      }}
    >
      <Typography
        variant="h5"
        component="h2"
        sx={{ mb: 0, color: 'text.primary', width: '100%', textAlign: 'center' }}
      >
        How Will You Use WordPath?
      </Typography>

      <FormControl sx={{ width: '100%', maxWidth: 440 }}>
        <RadioGroup
          aria-labelledby="role-selection-group-label"
          name="role-selection-buttons-group"
          value={currentRole}
          onChange={handleRadioRoleChange}
        >
          {roles.map((role) => {
            const RoleIcon = role.icon;
            const isSelected = currentRole === role.id || (role.id === 'teacher' && currentRole === 'tutor');
            
            return (
              <Paper 
                key={role.id}
                elevation={isSelected && !role.disabled ? 2 : 0}
                sx={{ 
                  mb: 2, 
                  borderRadius: 2,
                  border: isSelected && !role.disabled ? `2px solid ${role.color}` : '1px solid rgba(0, 0, 0, 0.12)',
                  transition: 'all 0.2s',
                  opacity: role.disabled ? 0.7 : 1,
                  cursor: role.disabled ? 'not-allowed' : 'pointer',
                  position: 'relative',
                  mx: 4,
                  '&:hover': {
                    borderColor: role.disabled ? 'rgba(0, 0, 0, 0.12)' : role.color,
                  }
                }}
              >
                <FormControlLabel
                  value={role.id}
                  disabled={role.disabled}
                  control={
                    <Radio 
                      sx={{ 
                        color: role.disabled ? 'rgba(0, 0, 0, 0.26)' : role.color,
                        '&.Mui-checked': {
                          color: role.disabled ? 'rgba(0, 0, 0, 0.26)' : role.color,
                        }
                      }} 
                    />
                  }
                  sx={{ 
                    m: 0, 
                    width: '100%', 
                    py: 1.5, 
                    px: 1.5,
                    '& .MuiFormControlLabel-label': { 
                      width: '100%' 
                    }
                  }}
                  label={
                    <Box sx={{ width: '100%' }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pl: 3.5 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <RoleIcon 
                            sx={{ 
                              fontSize: 20, 
                              color: isSelected && !role.disabled ? role.color : 'text.secondary',
                              mr: 1
                            }} 
                          />
                          <Typography 
                            sx={{ 
                              fontWeight: isSelected && !role.disabled ? 'bold' : 'normal',
                              color: isSelected && !role.disabled ? role.color : 'text.primary'
                            }}
                          >
                            {role.label}
                          </Typography>
                        </Box>
                        {role.disabled && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: 'text.secondary',
                              fontSize: '0.7rem',
                              fontStyle: 'italic',
                              ml: 1
                            }}
                          >
                            Coming soon
                          </Typography>
                        )}
                      </Box>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5, pl: 3.5 }}>
                        {role.description}
                      </Typography>
                    </Box>
                  }
                />
              </Paper>
            );
          })}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}
