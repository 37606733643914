const STORAGE_KEY = 'shareAccessStatus';

export const ShareAccessStatus = {
  COMPLETED: 'COMPLETED'
};

export const getShareAccessStatus = () => localStorage.getItem(STORAGE_KEY) || null;

export const setShareAccessStatus = (status) => {
  if (status === null) {
    localStorage.removeItem(STORAGE_KEY);
  } else {
    localStorage.setItem(STORAGE_KEY, status);
  }
}; 