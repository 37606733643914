import { useContext } from 'react';
import { Container, Paper } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { Form } from './components/Form';
import { ROUTES } from '../../routes';
import { Breadcrumb } from '../../components/layout/Breadcrumb';

export function ProfilePage() {
  const user = useContext(UserContext);

  // TODO: We should also block it server side so no one can mock POST request
  if (!user.isTeacher) {
    return <Navigate replace to={ROUTES.HOME} />;
  }

  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 2 }}>
        <Form user={user} />
      </Paper>
    </Container>
  );
}
