import { useQuery } from '@tanstack/react-query';
import { API_BASE_URL } from '../../../../../config';

async function fetchTeacherStats() {
  const response = await fetch(`${API_BASE_URL}/api/v1/teacher/stats`, {
    credentials: 'include',
  });
  
  if (!response.ok) {
    throw new Error('Failed to fetch teacher stats');
  }
  
  return response.json();;
}

export function useTeacherStats() {
  return useQuery({
    queryKey: ['teacherStats'],
    queryFn: fetchTeacherStats,
  });
} 