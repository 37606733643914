import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Container,
  Divider,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Link,
  IconButton
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SendIcon from '@mui/icons-material/Send';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Breadcrumb } from '../components/layout/Breadcrumb';

const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validate = () => {
    const newErrors = {};
    
    if (!formValues.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formValues.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
      newErrors.email = 'Invalid email address';
    }
    
    if (!formValues.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    
    if (!formValues.message.trim()) {
      newErrors.message = 'Message is required';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validate()) {
      // Here you would normally send the data to your backend
      console.log('Form submitted:', formValues);
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Your message has been sent successfully! We will get back to you soon.',
        severity: 'success'
      });
      
      // Reset form
      setFormValues({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Breadcrumb />
      <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 600, mb: 4 }}>
        Contact Us
      </Typography>
      
      <Grid container spacing={4}>
        {/* Contact Information */}
        <Grid item xs={12} md={4}>
          <Card elevation={1} sx={{ height: '100%', bgcolor: 'background.paper' }}>
            <CardContent sx={{ p: 3 }}>
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
                Contact Information
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <EmailOutlinedIcon sx={{ color: 'primary.main', mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Email
                  </Typography>
                  <Link href="mailto:contact@wordpath.com" underline="hover" color="text.primary">
                    contact@wordpath.com
                  </Link>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <PhoneOutlinedIcon sx={{ color: 'primary.main', mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Phone
                  </Typography>
                  <Link href="tel:+14155552671" underline="hover" color="text.primary">
                    +1 (415) 555-2671
                  </Link>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <LocationOnOutlinedIcon sx={{ color: 'primary.main', mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary">
                    Office
                  </Typography>
                  <Typography variant="body1">
                    550 Market Street, San Francisco, CA 94104
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box sx={{ width: 24, mr: 2 }} />
                <Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1.5 }}>
                    Social Media
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <Link 
                      href="https://facebook.com/wordpath" 
                      target="_blank" 
                      sx={{ 
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#4267B2',
                        backgroundColor: 'rgba(66, 103, 178, 0.1)',
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        transition: 'all 0.2s',
                        '&:hover': { 
                          backgroundColor: 'rgba(66, 103, 178, 0.2)',
                          transform: 'scale(1.1)'
                        }
                      }}
                      aria-label="Facebook"
                    >
                      <FacebookIcon fontSize="small" />
                    </Link>
                    <Link 
                      href="https://instagram.com/wordpath" 
                      target="_blank" 
                      sx={{ 
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#E1306C',
                        backgroundColor: 'rgba(225, 48, 108, 0.1)',
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        transition: 'all 0.2s',
                        '&:hover': { 
                          backgroundColor: 'rgba(225, 48, 108, 0.2)',
                          transform: 'scale(1.1)'
                        }
                      }}
                      aria-label="Instagram"
                    >
                      <InstagramIcon fontSize="small" />
                    </Link>
                    <Link 
                      href="https://twitter.com/wordpath" 
                      target="_blank" 
                      sx={{ 
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#1DA1F2',
                        backgroundColor: 'rgba(29, 161, 242, 0.1)',
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        transition: 'all 0.2s',
                        '&:hover': { 
                          backgroundColor: 'rgba(29, 161, 242, 0.2)',
                          transform: 'scale(1.1)'
                        }
                      }}
                      aria-label="Twitter"
                    >
                      <TwitterIcon fontSize="small" />
                    </Link>
                    <Link 
                      href="https://linkedin.com/company/wordpath" 
                      target="_blank" 
                      sx={{ 
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#0A66C2',
                        backgroundColor: 'rgba(10, 102, 194, 0.1)',
                        width: 32,
                        height: 32,
                        borderRadius: '50%',
                        transition: 'all 0.2s',
                        '&:hover': { 
                          backgroundColor: 'rgba(10, 102, 194, 0.2)',
                          transform: 'scale(1.1)'
                        }
                      }}
                      aria-label="LinkedIn"
                    >
                      <LinkedInIcon fontSize="small" />
                    </Link>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        
        {/* Contact Form */}
        <Grid item xs={12} md={8}>
          <Paper elevation={1} sx={{ p: 3 }}>
            <Typography variant="h6" sx={{ mb: 3, fontWeight: 500 }}>
              Send Us a Message
            </Typography>
            
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    label="Your Name"
                    fullWidth
                    variant="outlined"
                    value={formValues.name}
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    required
                    size="medium"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    label="Your Email"
                    fullWidth
                    variant="outlined"
                    value={formValues.email}
                    onChange={handleChange}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    required
                    size="medium"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    name="subject"
                    label="Subject"
                    fullWidth
                    variant="outlined"
                    value={formValues.subject}
                    onChange={handleChange}
                    error={Boolean(errors.subject)}
                    helperText={errors.subject}
                    required
                    size="medium"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Message"
                    fullWidth
                    variant="outlined"
                    value={formValues.message}
                    onChange={handleChange}
                    error={Boolean(errors.message)}
                    helperText={errors.message}
                    required
                    multiline
                    rows={6}
                    size="medium"
                  />
                </Grid>
                
                <Grid item xs={12} sx={{ textAlign: 'right', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={<SendIcon />}
                    sx={{ px: 4 }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactPage; 