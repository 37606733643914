import { useContext, useState } from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';
import { useTeacherStats } from './api/useTeacherStats';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { OnboardingPanel } from './components/OnboardingPanel';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';

export function TeacherDashboard() {
  const user = useContext(UserContext);
  const { data: stats, isLoading: statsLoading, isError: statsError, error: statsErrorData } = useTeacherStats();
  const showOnboarding = useState(!user?.onboardingFinished);

  const userLoading = false;

  if (userLoading || statsLoading) return <LoadingIndicator />;
  if (statsError) return <LoadingError error={statsErrorData} />;

  return (
    <Container sx={{ mt: 4 }}>
      <Breadcrumb />
      {showOnboarding && <OnboardingPanel />}

      <Paper sx={{ p: 3, mb: 4 }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: 4 }}>
          <Box>
            <Typography variant="h6" color="primary.main">
              {stats.assignmentsCount}
            </Typography>
            <Typography color="text.secondary">Assignments Created</Typography>
          </Box>
          <Box>
            <Typography variant="h6" color="primary.main">
              {stats.studentsCount} students in {stats.classesCount} classes
            </Typography>
            <Typography color="text.secondary">Total Students</Typography>
          </Box>
          <Box>
            <Typography variant="h6" color="primary.main">
              {stats.submittedAssignmentsCount}
            </Typography>
            <Typography color="text.secondary">Assignments Submitted</Typography>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
} 