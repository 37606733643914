import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { DemoAssignmentsList } from './DemoAssignmentsList';

export function DemoCard({ onDismiss, demoAssignments }) {
  return (
    <Box sx={{ marginBottom: 2, position: 'relative',  boxShadow: 2 }}>
      <IconButton onClick={onDismiss} size="small" sx={{ position: 'absolute', top: 8, right: 8 }}>
        <CloseIcon color="primary" />
      </IconButton>

      <DemoAssignmentsList assignments={demoAssignments} sx={{ bgcolor: 'rgb(248, 249, 250)', paddingBottom: 2 }} />
    </Box>
  );
}
