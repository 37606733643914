import { Link } from 'react-router-dom';
import { ROUTES } from '../routes';

const Logo = ({ imgStyle, style }) => (
  <Link to={ROUTES.HOME} style={{ textDecoration: 'none', ...style }}>
    <picture>
      <source srcSet="/logo.png, /logo@2x.png 2x" />
      <img 
        alt="WordPath" 
        style={{ 
          maxHeight: '28px', 
          width: 'auto', 
          ...imgStyle 
        }} 
      />
    </picture>
  </Link>
);

export default Logo;
