import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6ec6ca',
      main: '#007892',
      dark: '#00675b',
      contrastText: '#fff',
    },
    success: {
      light: '#8ddcbc',
      main: '#43a07a',
      dark: '#0a8352',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: '#000',
    },
    error: {
      light: '#e57373',
      main: '#d32f2f',
      dark: '#b71c1c',
      contrastText: '#fff',
    },
    text: {
      main: '#1E2435',
    },
  },
});
