import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const Footer = ({ sx }) => (
  <Box
    sx={{
      width: '100%',
      marginTop: 'auto',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      textAlign: 'center',
      borderColor: 'divider',
      ...sx
    }}
  >
    <span
      style={{
        fontSize: '0.75rem',
        color: 'text.secondary',
        maxWidth: '100%',
        display: 'inline-block',
        margin: '0 auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      By continuing, you agree to our{' '}
      <Link to="https://www.wordpath.com/terms-and-conditions" style={{ color: '#3f51b5' }}>
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link to="https://www.wordpath.com/privacy-policy" style={{ color: '#3f51b5' }}>
        Privacy Policy
      </Link>
    </span>
  </Box>
);

export default Footer;
