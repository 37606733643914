import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const mutationFn = () =>
  axios.request({
    method: 'patch',
    url: `/api/profile/finish-onboarding`,
    baseURL: API_BASE_URL,
    withCredentials: true
  });

export const useFinishOnboardingMutation = ({ onSuccess } = {}) => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['authenticated-user']);
      
      enqueueSnackbar(
        'Onboarding finished!',
        {
          autoHideDuration: 5000,
          variant: 'success',
        }
      );
      onSuccess?.();
    },
    onError: (error) => {
      enqueueSnackbar(error.response?.data?.error || 'Failed to finish onboarding', {
        autoHideDuration: 5000,
        variant: 'error',
      });
    },
  });
}; 