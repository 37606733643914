import { useState } from 'react';
import { Box, Paper, IconButton, Typography, Button, Tooltip, Modal, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import GradingIcon from '@mui/icons-material/Grading';
import ShareIcon from '@mui/icons-material/Share';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../routes';
import { StepItem } from './StepItem';
import { ShareAccessStatus, getShareAccessStatus, setShareAccessStatus } from '../utils/shareAccessStatus';
import { useFinishOnboardingMutation } from '../../../../profile/api/useFinishOnboardingMutation';
import { useAssignments } from '../../../../assignments/api/useAssignments';
import { useCoursesList } from '../../../../classes/api/useCoursesList';
import tryDemoImage from '../../../../../assets/try-demo.png';
import demoTemplate from '../../../../../assets/demo-template.png';
import demoAssignment from '../../../../../assets/demo-assignment.png';

const styles = {
  paper: {
    p: 2.5,
    mb: 3,
    position: 'relative',
    border: '1px solid',
    borderColor: 'divider',
    backgroundColor: '#f8f9fa'
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey.500'
  },
  title: {
    color: 'primary.dark',
    pr: 4,
    mb: 3,
    fontWeight: 500
  },
  section: {
    display: 'flex',
    gap: 3,
    mb: 4,
    '&:last-child': {
      mb: 0
    }
  },
  columnLayout: {
    display: 'flex',
    gap: 3,
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  columnWide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    borderRight: '1px solid',
    borderColor: 'divider',
    pr: 2
  },
  columnNarrow: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    pl: 2
  },
  sectionPrefix: {
    color: 'primary.dark',
    fontSize: '1.1rem',
    fontWeight: 500,
    fontStyle: 'italic',
    mb: 3,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    margin: '0 auto 20px auto',
    '&::before, &::after': {
      content: '""',
      display: 'inline-block',
      width: '25px',
      height: '1px',
      backgroundColor: 'primary.dark',
      opacity: 0.5
    }
  },
  introButtons: {
    mb: 2.5,
    display: 'flex',
    gap: 2,
    flexWrap: 'wrap'
  },
  introButton: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    py: 0.25,
    px: 1,
    fontSize: '0.75rem',
    minHeight: '24px',
    minWidth: '100px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 3,
    bgcolor: 'white',
    borderRadius: 2,
    boxShadow: 1,
    mx: 'auto',
    width: 'auto',
    minWidth: '300px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 3
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    width: '100%'
  },
  variantTitle: {
    color: 'text.secondary',
    fontSize: '0.875rem',
    mb: 2,
    textTransform: 'uppercase',
    letterSpacing: '0.5px'
  },
  needHelp: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mt: 2,
    color: 'primary.main',
    textDecoration: 'none',
    fontSize: '0.875rem',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  introItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
    maxWidth: '170px',
    minWidth: '150px',
  },
  introThumb: {
    width: '165px',
    height: '115px',
    backgroundColor: 'white',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: 'divider',
  },
  introRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    width: '100%',
  },
  introRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    flexWrap: 'wrap',
    justifyContent: 'center',
    mx: 'auto',
    width: '100%',
  },
};

export const OnboardingPanel = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [shareAccessStatus, setLocalShareAccessStatus] = useState(getShareAccessStatus());
  const [videoOpen, setVideoOpen] = useState(false);
  const navigate = useNavigate();

  const { data: assignments=[] } = useAssignments();
  const { data: classes = [] } = useCoursesList();

  const finishOnboardingMutation = useFinishOnboardingMutation({
    onSuccess: () => setIsVisible(false)
  });

  if (!isVisible) {
    return null;
  }

  const hasClasses = classes.length > 0;
  const hasAssignments = assignments.length > 0;
  const hasAssignmentsAndClasses = hasAssignments && hasClasses;
  const hasSharedAccess = shareAccessStatus === ShareAccessStatus.COMPLETED;
 
  const hasAssignedStudents = assignments.some(assignment => assignment.userAssignments.length > 1);
  const canReviewWork = hasAssignments && hasAssignedStudents && hasSharedAccess;

  const firstUnassignedAssignment = assignments.find(a => !a.students?.length);
  const firstAssignmentWithStudents = assignments.find(
    assignment => assignment.userAssignments.length > 1
  );

  const handleCompleteSharing = () => {
    const status = ShareAccessStatus.COMPLETED;
    setShareAccessStatus(status);
    setLocalShareAccessStatus(status);
  };

  const finishOnboarding = () => {
    finishOnboardingMutation.mutate();
  };

  const handleComplete = () => {
    if (finishOnboarding) {
      finishOnboarding();
    }
    navigate('/dashboard/teacher/assignments');
  };

  const handleOpenVideo = () => {
    setVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setVideoOpen(false);
  };

  return (
    <Paper sx={styles.paper}>
      <Tooltip title="Close Onboarding Panel">
        <IconButton onClick={handleComplete} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
      
      <Box sx={styles.columnLayout}>
        {/* Pierwsza kolumna - Discover WordPath */}
        <Box sx={styles.columnWide}>
          <Typography sx={styles.sectionPrefix}>Discover WordPath</Typography>
          <Box sx={styles.introRowContainer}>
            <Box sx={styles.introRow}>
              <Box sx={styles.introItem}>
                <Box sx={styles.introThumb}>
                  <img
                    src={tryDemoImage}
                    alt="Student Demo Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'none' }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  href="/assignments/c037f991-5b31-4c8e-ae8d-93f3b4d0c397/3/write"
                  sx={styles.introButton}
                >
                  Try Demo Assignment
                </Button>
              </Box>

              <Box sx={styles.introItem}>
                <Box sx={styles.introThumb}>
                  <img
                    src="https://img.youtube.com/vi/AebEjDjRt-Q/maxresdefault.jpg"
                    alt="WordPath Tutorial Video Thumbnail"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  onClick={handleOpenVideo}
                  sx={styles.introButton}
                >
                  Watch Quick Overview
                </Button>
              </Box>
            </Box>
            
            <Box sx={styles.introRow}>
              <Box sx={styles.introItem}>
                <Box sx={styles.introThumb}>
                  <img
                    src={demoAssignment}
                    alt="Sample Assignment Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'none', padding: '8px' }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  href="/assignments/c037f991-5b31-4c8e-ae8d-93f3b4d0c397"
                  sx={styles.introButton}
                >
                  See Sample Assignment
                </Button>
              </Box>

              <Box sx={styles.introItem}>
                <Box sx={styles.introThumb}>
                  <img
                    src={demoTemplate}
                    alt="Sample Template Preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover', padding: '8px' }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  href="/templates/featured"
                  sx={styles.introButton}
                >
                  See Sample Template
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Druga kolumna - Start teaching now! */}
        <Box sx={styles.columnNarrow}>
          <Typography sx={styles.sectionPrefix}>Start teaching now!</Typography>
          <Box sx={styles.root}>
            <Box sx={styles.list}>
              <StepItem
                icon={SchoolIcon}
                isCompleted
                isActive
                linkTo={ROUTES.CLASSES_NEW}
                linkText="Add Students"
                description="Set Up a Class or Sync with Your LMS"
                faqLink="/faq/classes"
                index={0}
              />

              <StepItem
                icon={AssignmentIcon}
                isCompleted={false}
                isActive
                linkTo={ROUTES.ASSIGNMENTS_NEW}
                linkText="Create Assignment"
                description="Create New or Choose an Outline Template"
                faqLink="/faq/assignments"
                index={1}
              />

              <StepItem
                icon={EditNoteIcon}
                isCompleted={hasAssignedStudents}
                isActive={hasAssignmentsAndClasses}
                linkTo={firstUnassignedAssignment ? ROUTES.assignToAssignment(firstUnassignedAssignment.id) : null}
                linkText="Assign Work"
                description="Assign to the Class or Select Students Individually"
                faqLink="/faq/assign"
                index={2}
              />

              <StepItem
                icon={ShareIcon}
                isCompleted={hasSharedAccess}
                isActive={hasAssignedStudents}
                linkTo={ROUTES.CLASSES_INDEX}
                linkText="Distribute Login Details"
                description="Share Access Codes or LMS Login Information"
                faqLink="/faq/sharing"
                index={3}
                actionButtons={
                  hasClasses &&
                  !hasSharedAccess && (
                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={handleCompleteSharing}
                        disabled={!hasAssignedStudents}
                        sx={styles.button}
                      >
                        Mark as done
                      </Button>
                    </Box>
                  )
                }
              />

              <StepItem
                icon={GradingIcon}
                isCompleted={false}
                isActive={canReviewWork}
                linkTo={
                  firstAssignmentWithStudents
                    ? `${ROUTES.showAssignment(firstAssignmentWithStudents.id)}`
                    : ROUTES.ASSIGNMENTS_INDEX
                }
                linkText="Review Submissions"
                description="See Student Submissions, AI Summaries, and Writing Replays"
                faqLink="/faq/review"
                isLast
                index={4}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      
      <Modal
        open={videoOpen}
        onClose={handleCloseVideo}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '900px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 1,
          borderRadius: 1,
        }}>
          <Box sx={{ position: 'relative', paddingTop: '56.25%', width: '100%' }}>
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none'
              }}
              src="https://www.youtube.com/embed/AebEjDjRt-Q?autoplay=1"
              title="WordPath Tutorial Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
          <IconButton
            sx={{
              position: 'absolute',
              top: -40,
              right: -40,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                bgcolor: 'rgba(0,0,0,0.7)',
              },
            }}
            onClick={handleCloseVideo}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>
    </Paper>
  );
}; 