import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 1.5,
    mb: 0.5,
    position: 'relative',
  },
  stepIcon: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
    marginTop: '5px'
  },
  timelineLine: {
    position: 'absolute',
    top: '32px',
    height: '16px',
    left: '12px',
    width: '2px',
    backgroundColor: 'divider',
    opacity: 0.6,
    zIndex: 0
  },
  content: {
    flex: 1,
    minWidth: 0
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    mb: 0.25
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    color: 'primary.dark',
    fontWeight: 500,
    textDecoration: 'none',
    position: 'relative',
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  titleActive: {
    color: 'primary.main',
    fontWeight: 600,
    fontSize: '0.875rem'
  },
  titleCompleted: {
    color: 'primary.main',
    fontWeight: 500,
    fontSize: '0.875rem',
    opacity: 0.8
  },
  titleInactive: {
    color: 'text.secondary',
    cursor: 'default',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  description: {
    color: 'text.secondary',
    fontSize: '0.75rem',
    mb: 0.25,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionButtons: {
    mt: 0.25
  }
};

export const StepItem = ({
  isCompleted,
  isActive,
  linkTo,
  linkText,
  description,
  faqLink,
  actionButtons,
  isLast,
  index
}) => {
  const isInactive = !isActive && !isCompleted;

  const content = (
    <Box sx={styles.content}>
      <Box sx={styles.header}>
        <Typography
          component={linkTo && isActive ? Link : 'div'}
          to={linkTo}
          sx={{
            ...styles.title,
            ...(isActive && !isCompleted && styles.titleActive),
            ...(isCompleted && styles.titleCompleted),
            ...(isInactive && styles.titleInactive)
          }}
        >
          {linkText}
        </Typography>
      </Box>
      <Typography sx={styles.description}>{description}</Typography>
      {actionButtons && <Box sx={styles.actionButtons}>{actionButtons}</Box>}
    </Box>
  );

  const timelineLineStyle = {
    ...styles.timelineLine,
    ...(isCompleted && { backgroundColor: 'primary.light', opacity: 0.7 }),
    ...(isActive && !isCompleted && { backgroundColor: 'primary.main', opacity: 0.5 })
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.stepIcon}>
        {isCompleted ? (
          <CheckCircleIcon sx={{ color: 'primary.main', opacity: 0.8, fontSize: '2rem' }} />
        ) : (
          <RadioButtonUncheckedIcon 
            sx={{ 
              color: isActive ? 'primary.main' : 'grey.300',
              fontSize: '2rem'
            }} 
          />
        )}
        {!isLast && <Box sx={timelineLineStyle} />}
      </Box>
      {content}
    </Box>
  );
}; 