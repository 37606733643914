import { useParams } from 'react-router-dom';
import { ButtonGroup, Container, Paper, Stack, TableBody, TableHead, Toolbar, Typography, Box, Grid } from '@mui/material';
import {
  Assignment as AssignmentIcon,
  Edit as EditIcon,
  ContentPaste as AnalyticsIcon,
  Share as ShareIcon,
  ContentPaste as ContentPasteIcon,
  School as GradeIcon,
  Psychology as SmartToyIcon,
  Photo as ImageIcon,
  Event as CalendarTodayIcon,
  Style as StyleIcon,
  Category as CategoryIcon,
} from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import { format } from 'date-fns';
import { isEmpty } from 'ramda';
import CircularProgress from '@mui/material/CircularProgress';
import { ROUTES } from '../../../routes';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { LoadingError } from '../../../components/LoadingError';
import { TestAssignmentMenu } from '../components/TestAssignmentMenu';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { Table, Tr, Td, Th } from '../../../components/layout/Table';
import { ButtonWithTooltip } from '../../../components/layout/ButtonWithTooltip';
import { UserAvatar } from '../../../components/Avatar';
import { BoolValue } from '../../../components/BoolValue';
import { AssignmentTypeText, getAssignmentStatus } from '../../../helpers/assignment';
import { useAssignment } from '../api/useAssignment';
import { UserAssignmentStatus } from '../../../helpers/enums';
import { useUnsubmitAssignment } from '../api/useUnsubmitAssignment';

export function AssignmentIndexPage() {
  const { assignmentId } = useParams();

  const { data: assignment, isLoading, isError, error } = useAssignment(assignmentId);

  const unsubmitMutation = useUnsubmitAssignment();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb assignmentId={assignmentId} assignmentTitle={assignment.title} />
      <Paper sx={{ marginBottom: 2 }}>
        <Box sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mb: 2 }}>
            <ButtonWithTooltip
              label="Edit"
              tooltip="You can edit assignment only if there are no assignees yet."
              tooltipPlacement="top"
              icon={<EditIcon />}
              to={ROUTES.editAssignment(assignmentId)}
              disabled={!isEmpty(assignment.students)}
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 500,
                px: 2,
                borderColor: 'divider',
                color: 'text.secondary',
                '&:hover': {
                  borderColor: 'primary.main',
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                },
                '&.Mui-disabled': {
                  color: 'text.disabled',
                },
              }}
            />
            <ButtonWithTooltip
              label="Assign"
              tooltip="Assign this assignment to students"
              tooltipPlacement="top"
              icon={<ShareIcon />}
              to={ROUTES.assignToAssignment(assignment.id)}
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                fontWeight: 500,
                px: 2,
                borderColor: 'divider',
                color: 'text.secondary',
                '&:hover': {
                  borderColor: 'primary.main',
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                },
              }}
            />
            <TestAssignmentMenu assignment={assignment} />
          </Box>
          <Paper
            elevation={0}
            sx={{
              background: 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
              borderRadius: 3,
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
              border: '1px solid',
              borderColor: 'divider',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                height: '3px',
                background: 'linear-gradient(90deg, #1976d2 0%, #64b5f6 100%)',
                opacity: 0.8,
              },
            }}
          >
            <Box sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, mb: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: 'text.secondary',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Title
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: '1px',
                      background: 'linear-gradient(to bottom, rgba(25, 118, 210, 0.2), rgba(100, 181, 246, 0.2))',
                      borderRadius: '1px',
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 600,
                      color: 'text.primary',
                      letterSpacing: '0.5px',
                      pl: 2,
                    }}
                  >
                    {assignment.title}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '120px' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: 'text.secondary',
                      letterSpacing: '0.5px',
                    }}
                  >
                    Instructions
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: '1px',
                      background: 'linear-gradient(to bottom, rgba(25, 118, 210, 0.2), rgba(100, 181, 246, 0.2))',
                      borderRadius: '1px',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontStyle: 'italic',
                      lineHeight: 1.8,
                      color: 'text.secondary',
                      fontSize: '1.1rem',
                      pl: 2,
                    }}
                  >
                    {assignment.instructions}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Box sx={{ display: 'flex', width: '100%', gap: 2, mt: 3 }}>
            <Box sx={{ flex: 1 }}>
              <Paper
                elevation={0}
                sx={{
                  bgcolor: 'background.default',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  height: '100%',
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: 'text.secondary',
                      letterSpacing: '0.5px',
                      mb: 2,
                    }}
                  >
                    Details
                  </Typography>
                  <Stack spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <StyleIcon color="primary" />
                      <Typography>
                        <strong>Type:</strong> {AssignmentTypeText[assignment.type]}
                      </Typography>
                    </Box>
                    {assignment.template && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ContentPasteIcon color="primary" />
                        <Typography>
                          <strong>Template:</strong> {assignment.template.name}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <GradeIcon color="primary" />
                      <Typography>
                        <strong>Grade:</strong> {assignment.grade ? assignment.grade : '-'}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CalendarTodayIcon color="primary" />
                      <Typography>
                        <strong>Due date:</strong> {assignment.dueDate ? format(assignment.dueDate, 'P') : '-'}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Box>

            <Box sx={{ flex: 1 }}>
              <Paper
                elevation={0}
                sx={{
                  bgcolor: 'background.default',
                  borderRadius: 2,
                  border: '1px solid',
                  borderColor: 'divider',
                  height: '100%',
                }}
              >
                <Box sx={{ p: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: 'text.secondary',
                      letterSpacing: '0.5px',
                      mb: 2,
                    }}
                  >
                    AI Features
                  </Typography>
                  <Stack spacing={2}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <SmartToyIcon color="primary" />
                      <Typography>
                        <strong>With Gwen AI help:</strong> <BoolValue value={assignment.useGwen} />
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <ImageIcon color="primary" />
                      <Typography>
                        <strong>Generate AI Image:</strong> <BoolValue value={assignment.generateImage} />
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
      </Paper>
      {assignment.ownerId && (
        <Table
          variant="outlined"
          caption="Assigned Users & Submissions"
          ariaLabel="Submissions"
          noData={assignment.students.length === 0}
        >
          <TableHead>
            <Tr>
              <Th>#</Th>
              <Th align="left">Student name</Th>
              <Th>Class</Th>
              <Th>Status</Th>
              <Th>Submitted Work Actions</Th>
            </Tr>
          </TableHead>
          <TableBody>
            {/* TODO: Change serialization, do not use assignment.studentAssignments and get back to this basic mapping */}
            {/* {assignment.students.map((student) => { */}
            {assignment.studentAssignments.map((studentAssignment, index) => {
              const student = studentAssignment.user;
              const externalTextPastePercentage = student.externalTextPastePercentage > 20;
              const assignedToClasses = student.courseUsers.map((courseUser) => courseUser.course.name);
              // TODO: There might be student without courseUsers (because removed from a class?) and then it fails
              const { courseId = -1 } = student.courseUsers?.[0] || {}; // TODO: what if multiple classes

              return (
                <Tr key={student.id}>
                  <Td>{index + 1}</Td>
                  <Td align="left">
                    <ButtonWithTooltip
                      label={student.name}
                      tooltip="View student profile"
                      icon={<UserAvatar alt={student.name} src={student.avatarUrl} />}
                      to={ROUTES.showStudent(courseId, student.id)}
                    />
                  </Td>
                  <Td>{assignedToClasses.join(', ')}</Td>
                  <Td>{getAssignmentStatus(studentAssignment.status)}</Td>
                  <Td>
                    <Stack direction="row" spacing={1} justifyContent="center">
                      {studentAssignment.status !== UserAssignmentStatus.CREATED && (
                        <ButtonWithTooltip
                          label="View"
                          tooltip={
                            student.assignmentStatus === UserAssignmentStatus.SUBMITTED
                              ? 'View submitted work'
                              : 'View work in progress'
                          }
                          icon={<AssignmentIcon />}
                          to={ROUTES.showAssignmentSubmission(assignment.id, student.id)}
                        />
                      )}
                      {studentAssignment.status === UserAssignmentStatus.SUBMITTED && (
                        <ButtonWithTooltip
                          label="Replay & Analysis"
                          tooltip={
                            externalTextPastePercentage
                              ? 'More than 20% of characters copied externally'
                              : 'View Replay & Analytics'
                          }
                          style={{ color: externalTextPastePercentage ? 'red' : '' }}
                          icon={<AnalyticsIcon style={{ color: externalTextPastePercentage ? 'red' : '' }} />}
                          to={ROUTES.showAssignmentAnalytics(assignment.id, student.id)}
                        />
                      )}
                      {studentAssignment.status === UserAssignmentStatus.SUBMITTED && (
                        <ButtonWithTooltip
                          label="Unsubmit"
                          tooltip="Allow student to make some edits and re-submit assignment"
                          icon={
                            unsubmitMutation.isLoading ? <CircularProgress size={20} color="inherit" /> : <ReplayIcon />
                          }
                          onClick={() => {
                            unsubmitMutation.mutate({ assignmentId: assignment.id, studentId: student.id });
                          }}
                          disabled={unsubmitMutation.isLoading}
                        />
                      )}
                    </Stack>
                  </Td>
                </Tr>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Container>
  );
}
