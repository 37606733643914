import { useContext, useState, useRef, useEffect } from 'react';
import {
  Avatar,
  Box,
  Container,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Badge,
  Tooltip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Collapse,
  ButtonGroup,
  Button,
  Switch,
  ListItemText,
} from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Drawer imports
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SchoolIcon from '@mui/icons-material/School';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
// import HelpIcon from '@mui/icons-material/Help';
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportIcon from '@mui/icons-material/Support';
import EmailIcon from '@mui/icons-material/Email';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

// Layout Imports
import { ROUTES } from '../routes';
import { API_BASE_URL } from '../config';
import { UserContext } from '../contexts/UserContext';
import { isEdlinkUser } from '../helpers/user';
import Logo from '../components/Logo';
import { NavMenuItem } from '../components/NavMenuItem';

const drawerTopHeight = 100;
const drawerWidth = 190;

const headerStyles = (theme) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: drawerTopHeight,

  [theme.breakpoints.up('sm')]: { flexDirection: 'row', justifyContent: 'space-between' },
});

function Header({ showLogo = false }) {
  return <Box sx={headerStyles}>{showLogo && <Logo />}</Box>;
}

// these are basic demo styles from the MUI docs
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  minHeight: 40,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  zIndex: 1200,
  '& .MuiDrawer-paper': {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    height: '100vh',
    zIndex: 1200,
    width: open ? drawerWidth : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: open ? drawerWidth : `calc(${theme.spacing(8)} + 1px)`,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
}));

export function DrawerLayout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(() => {
    const savedState = localStorage.getItem('drawerOpen');
    return savedState !== null ? JSON.parse(savedState) : false;
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverIntent, setHoverIntent] = useState(false);
  const [hideHelpButton, setHideHelpButton] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const location = useLocation();
  const avatarRef = useRef(null);
  const hoverTimerRef = useRef(null);
  const drawerHoverTimerRef = useRef(null);
  const [drawerHover, setDrawerHover] = useState(false);
  const drawerRef = useRef(null);

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem('drawerOpen', JSON.stringify(true));
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem('drawerOpen', JSON.stringify(false));
  };

  // Dodanie event listenera do kliknięć poza menu
  useEffect(() => {
    function handleClickOutside(event) {
      // Zamykamy menu tylko gdy jest otwarte i kliknięcie było poza menu
      if (open && drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleDrawerClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleDrawerMouseEnter = () => {
    if (!open) {
      clearTimeout(drawerHoverTimerRef.current);
      drawerHoverTimerRef.current = setTimeout(() => {
        setDrawerHover(true);
      }, 300);
    }
  };

  const handleDrawerMouseLeave = () => {
    clearTimeout(drawerHoverTimerRef.current);
    if (!open) {
      setDrawerHover(false);
    }
  };

  const handleMenuOpen = () => {
    clearTimeout(hoverTimerRef.current);
    setHoverIntent(true);
    setAnchorEl(avatarRef.current);
  };

  const handleMenuClose = () => {
    // Używamy timeouta, aby dać użytkownikowi czas na przesunięcie kursora na menu
    hoverTimerRef.current = setTimeout(() => {
      if (!hoverIntent) {
        setAnchorEl(null);
        // Pokazujemy przycisk Help po zamknięciu menu
        setHideHelpButton(false);
      }
    }, 200);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setAnchorEl(null);
    setHideHelpButton(false);
  };

  const handleProfileAreaMouseEnter = () => {
    setHoverIntent(true);
    handleMenuOpen();
  };

  const handleProfileAreaMouseLeave = () => {
    setHoverIntent(false);
    handleMenuClose();
  };

  const handleMenuMouseEnter = () => {
    setHoverIntent(true);
  };

  const handleMenuMouseLeave = () => {
    setHoverIntent(false);
    handleMenuClose();
  };

  const isMenuOpen = Boolean(anchorEl);
  const logout = () => window.open(`${API_BASE_URL}/auth/logout`, '_self');
  const userName = user ? user.name : '';

  // hmm czy potrzebne?
  useEffect(() => {
    if (user && user.role === 'NOT_SET' && location.pathname !== ROUTES.PROFILE_ROLE_SELECTION) {
      navigate(ROUTES.PROFILE_ROLE_SELECTION, { replace: true });
    }
  }, [user, location.pathname, navigate]);

  return (
    <Box sx={{ display: 'flex', position: 'relative', minHeight: '100vh', width: '100%' }}>
      <CssBaseline />
      {user && (
        <MuiDrawer
          variant="permanent"
          open={open || drawerHover}
          onMouseEnter={handleDrawerMouseEnter}
          onMouseLeave={handleDrawerMouseLeave}
          ref={drawerRef}
          sx={{
            width: (open || drawerHover) ? drawerWidth : `calc(${theme.spacing(8)} + 1px)`,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            zIndex: 1200,
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100%',
            '& .MuiDrawer-paper': {
              position: 'fixed',
              top: 0,
              left: 0,
              height: '100%',
              overflowX: 'hidden',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
              borderRight: '1px solid rgba(0, 0, 0, 0.08)',
              width: (open || drawerHover) ? drawerWidth : `calc(${theme.spacing(8)} + 1px)`,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          <DrawerHeader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: open || drawerHover ? 'center' : 'center',
              px: 1.5,
              height: 48,
              minHeight: 48,
              py: 0.5,
            }}
          >
            {open || drawerHover ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Logo imgStyle={{ maxHeight: '24px', display: 'block' }} />
              </Box>
            ) : (
              <IconButton onClick={handleDrawerOpen} size="small">
                <MenuIcon />
              </IconButton>
            )}
          </DrawerHeader>
          <Divider />
          <List
            sx={{
              pt: 0.5,
              px: 1,
              pb: 0,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* Sekcja główna - kolor indigo dla spójności */}
            <Box sx={{ 
              mb: 0.5, 
              pt: 0, 
              borderRadius: 1.5,
              backgroundColor: 'rgba(63, 81, 181, 0.04)'
            }}>
              {!user?.isTeacher && (
                <NavMenuItem
                  title="Dashboard"
                  icon={DashboardIcon}
                  to={ROUTES.STUDENT_DASHBOARD}
                  selected={location.pathname.includes('/student/')}
                  disableHoverListener={open || drawerHover}
                  sx={{ display: user.role === 'NOT_SET' ? 'none' : 'flex' }}
                  iconColor="#3f51b5"
                />
              )}
            </Box>

            {/* Sekcja nauczycielska - kolor indigo/fioletowy */}
            {user?.isTeacher && user.role !== 'NOT_SET' && (
              <Box sx={{ 
                mb: 0.5, 
                mt: 0.5, 
                borderRadius: 1.5,
                backgroundColor: 'rgba(63, 81, 181, 0.04)'
              }}>
                <NavMenuItem
                  title="Dashboard"
                  icon={DashboardIcon}
                  to={ROUTES.DASHBOARD_HOME}
                  selected={location.pathname === ROUTES.DASHBOARD_HOME}
                  disableHoverListener={open || drawerHover}
                  sx={{ display: user.role === 'NOT_SET' ? 'none' : 'flex' }}
                  iconColor="#3f51b5"
                />
                <NavMenuItem
                  title="Assignments"
                  icon={AssignmentIcon}
                  to={ROUTES.ASSIGNMENTS_INDEX}
                  selected={location.pathname.startsWith(ROUTES.ASSIGNMENTS_INDEX)}
                  disableHoverListener={open || drawerHover}
                  iconColor="#3f51b5"
                />
                <NavMenuItem
                  title="Templates"
                  icon={EditNoteIcon}
                  to={ROUTES.TEMPLATES_INDEX}
                  selected={location.pathname.startsWith(ROUTES.TEMPLATES_INDEX)}
                  disableHoverListener={open || drawerHover}
                  iconColor="#3f51b5"
                />
                <NavMenuItem
                  title="Classes"
                  icon={SchoolIcon}
                  to={ROUTES.CLASSES_INDEX}
                  selected={location.pathname.startsWith(ROUTES.CLASSES_INDEX)}
                  disableHoverListener={open || drawerHover}
                  iconColor="#3f51b5"
                />
              </Box>
            )}
            
            {/* Sekcja admina - kolor szary */}
            {user.isAdmin && user.role !== 'NOT_SET' && (
              <Box sx={{ 
                mb: 0.5, 
                mt: 1.5, 
                borderRadius: 1.5,
                backgroundColor: 'rgba(117, 117, 117, 0.05)'
              }}>
                <NavMenuItem
                  title="Prompts"
                  icon={TuneIcon}
                  to={ROUTES.ADMIN_PROMPTS_CONFIGURATIONS}
                  selected={location.pathname === ROUTES.ADMIN_PROMPTS_CONFIGURATIONS}
                  disableHoverListener={open || drawerHover}
                  iconColor="#757575"
                />
                <NavMenuItem
                  title="Users"
                  icon={ManageAccountsIcon}
                  to={ROUTES.ADMIN_USERS}
                  selected={location.pathname === ROUTES.ADMIN_USERS}
                  disableHoverListener={open || drawerHover}
                  iconColor="#757575"
                />
                <NavMenuItem
                  title="EdLink"
                  icon={IntegrationInstructionsIcon}
                  to={ROUTES.ADMIN_EDLINK}
                  selected={location.pathname === ROUTES.ADMIN_EDLINK}
                  disableHoverListener={open || drawerHover}
                  iconColor="#757575"
                />
              </Box>
            )}
            
            <Box sx={{ flex: 1 }} />
            
            {/* Sekcja pomocy - kolor ciemniejszy zielony */}
            <Box sx={{ 
              mb: 2.5,
              borderRadius: 1.5,
              backgroundColor: 'rgba(56, 142, 60, 0.05)'
            }}>
              <NavMenuItem
                title="Contact Us"
                icon={EmailOutlinedIcon}
                to={ROUTES.CONTACT}
                selected={location.pathname === ROUTES.CONTACT}
                disableHoverListener={open || drawerHover}
                iconColor="#4caf50"
              />
              <NavMenuItem
                title="Help"
                icon={HelpOutlineIcon}
                to={ROUTES.HELP}
                selected={location.pathname === ROUTES.HELP}
                disableHoverListener={open || drawerHover}
                iconColor="#4caf50"
              />
            </Box>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  borderRadius: 1,
                  overflow: 'hidden',
                  mb: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.02)',
                  },
                }}
              >
                <ListItemButton
                  ref={avatarRef}
                  onMouseEnter={handleProfileAreaMouseEnter}
                  onMouseLeave={handleProfileAreaMouseLeave}
                  id="avatar-button"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 0.5,
                    width: '100%',
                  }}
                >
                  {user?.earlyAccess ? (
                    <Avatar
                      alt={userName}
                      src={user?.avatarUrl}
                      sx={{ width: 40, height: 40, border: '2px solid rgba(0, 0, 0, 0.15)' }}
                    />
                  ) : (
                    <Avatar
                      alt={userName}
                      src={user?.avatarUrl}
                      sx={{ width: 40, height: 40, border: '2px solid rgba(0, 0, 0, 0.1)' }}
                    />
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '0.85rem',
                      textAlign: open || drawerHover ? 'left' : 'center',
                      maxWidth: '90%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'none',
                    }}
                  >
                    {userName}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </Box>
          </List>
        </MuiDrawer>
      )}

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          onMouseEnter: handleMenuMouseEnter,
          onMouseLeave: handleMenuMouseLeave,
          dense: true,
          sx: { width: '100%' },
        }}
        disableAutoFocusItem
        disableRestoreFocus
        PopoverClasses={{
          paper: 'menu-popover-paper',
        }}
        sx={{
          mb: 1,
          zIndex: 1500,
          position: 'absolute',
          '& .MuiPaper-root': {
            borderRadius: 2,
            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.04)',
            border: '1px solid',
            borderColor: 'divider',
            width: 220,
            minWidth: 220,
          },
          '& .MuiMenuItem-root': {
            minHeight: 36,
            py: 0.5,
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: 36,
          },
          '& .MuiTypography-root': {
            fontSize: '0.85rem',
          },
        }}
      >
        <Box
          sx={{
            mb: 0.5,
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick(ROUTES.PROFILE)}>
            <ListItemIcon>
              <PersonIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="body2">Profile</Typography>
          </MenuItem>
        </Box>
        {user && user.isTeacher && (
          <MenuItem onClick={() => handleMenuItemClick(ROUTES.BILLING)}>
            <ListItemIcon>
              <PaymentIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="body2">Subscription</Typography>
          </MenuItem>
        )}
        {isEdlinkUser(user) && (
          <MenuItem onClick={() => handleMenuItemClick(ROUTES.PROFILE_DATA_SYNC)}>
            <ListItemIcon>
              <IntegrationInstructionsIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <Typography variant="body2">Data Sync</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={logout} sx={{ color: theme.palette.error.main }}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography variant="body2">Logout</Typography>
        </MenuItem>
      </Menu>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 1.5,
          pb: 3,
          pt: 0,
          width: '100%',
          maxWidth: '1200px',
          // marginLeft: '70px',
          marginX: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
