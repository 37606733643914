import { Box, Button, Modal, Typography } from '@mui/material';

import { GOOGLE_SIGN_IN_URL } from '../config';

export function RequireLoginModal({ onComplete }) {
  const handleLogin = () => {
    const popup = window.open(GOOGLE_SIGN_IN_URL, '_blank', { popup: true });
    popup.addEventListener('load', () => {
      const observer = new MutationObserver(() => {
        if (popup.location.origin === window.location.origin) {
          observer.disconnect();
          popup.close();
          onComplete();
        }
      });
      observer.observe(popup.document.querySelector('body'), {
        childList: true,
        subtree: true,
      });
    });
  };

  return (
    <Modal open onClose={onComplete} aria-labelledby="require-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          padding: 4,
        }}
      >
        <Typography component="h2" id="require-modal-title" variant="h5">
          Your session has expired
        </Typography>
        <Typography>You need to log in again to continue.</Typography>
        <Box sx={{ paddingTop: 3, textAlign: 'center' }}>
          <Button onClick={handleLogin} variant="outlined">
            Log in again to continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
