import { Navigate, useSearchParams } from 'react-router-dom';
import { Box, Grid, Container } from '@mui/material';
import { useState, useEffect } from 'react';
import SignIn from './components/SignIn';
import Footer from '../../components/Footer';
import { RoleSelection } from './components/RoleSelection';
import gwenTeacher from '../../assets/gwen-teacher-table.jpg';
import gwenStudent from '../../assets/gwen-student.png';
import gwenHello from '../../assets/gwen-hello.png';

export function SignInPage() {
  const [searchParams] = useSearchParams();
  const role = searchParams.get('role');
  const showCreate = searchParams.get('create') === 'true';
  const [showRoleSelection, setShowRoleSelection] = useState(showCreate);

  useEffect(() => {
    setShowRoleSelection(showCreate);
  }, [showCreate]);

  // Blokuj scroll na poziomie body
  useEffect(() => {
    // Zapisz oryginalny styl
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Ustaw overflow: hidden na body
    document.body.style.overflow = 'hidden';
    
    // Przywróć oryginalny styl przy odmontowaniu
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  if (!role) {
    return <Navigate to="/sign-in?role=teacher" />;
  }

  if (!['student', 'teacher'].includes(role)) {
    return <Navigate to="/sign-in?role=teacher" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Grid container sx={{ flex: 1, overflow: 'hidden' }}>
          {/* Lewa kolumna */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: { xs: 2, md: 3 },
              overflow: 'auto',
            }}
          >
            {/* Separator po prawej stronie dla większych ekranów */}
            <Box
              sx={{
                display: { xs: 'none', md: 'block' },
                position: 'absolute',
                right: 0,
                top: '15%',
                bottom: '15%',
                width: '1px',
                bgcolor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
            {/* Separator na dole dla mniejszych ekranów */}
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
                position: 'absolute',
                bottom: 0,
                left: '15%',
                right: '15%',
                height: '1px',
                bgcolor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
            <Box sx={{ mb: 0, textAlign: 'center' }}>
              {showRoleSelection ? (
                <img
                  src={role === 'teacher' ? gwenTeacher : gwenStudent}
                  alt={role === 'teacher' ? 'Student Gwen' : 'Teacher Gwen'}
                  style={{
                    height: '190px',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    position: 'relative',
                    width: '240px',
                    height: '240px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                  }}
                >
                  <img
                    src={gwenHello}
                    alt="Gwen Hello"
                    style={{
                      width: '240px',
                      height: '240px',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              )}
            </Box>

            {showRoleSelection ? (
              <RoleSelection />
            ) : (
              <Box
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="h2"
                  sx={{
                    fontSize: { xs: '2rem', sm: '2.2rem' },
                    fontWeight: 700,
                    mt: 3,
                    mb: 1,
                    textAlign: 'center',
                    width: '100%',
                    '& span': {
                      color: 'primary.main',
                    },
                  }}
                >
                  Welcome to <span>WordPath</span>
                </Box>

                <Box
                  component="p"
                  sx={{
                    fontWeight: 500,
                    fontSize: '1.1rem',
                    mt: 0,
                    mb: 2,
                    color: 'text.primary',
                    letterSpacing: '0.2px',
                    textAlign: 'center',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'visible',
                  }}
                >
                  The Ultimate Platform for Student Writing
                </Box>

                <Box
                  component="p"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.9rem',
                    mt: 1,
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: '300px',
                  }}
                >
                  Empowering teachers and students
                  <br />
                  with AI-powered writing tools
                </Box>
              </Box>
            )}
          </Grid>

          {/* Prawa kolumna */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: { xs: 2, md: 3 },
              overflow: 'auto',
            }}
          >
            <SignIn role={role} onCreateAccount={() => setShowRoleSelection(true)} isSignUp={showRoleSelection} />
          </Grid>
        </Grid>
      </Container>

      <Footer sx={{ py: 0.5, mt: 'auto', borderTopWidth: '1px' }} />
    </Box>
  );
}
