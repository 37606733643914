import { Box, Button, TextField } from '@mui/material';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { API_BASE_URL } from '../../../config';

export function CodeLogin() {
  const [code, setCode] = useState();
  // const [error, setError] = useState('');
  //  const navigate = useNavigate();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);
  //   console.log('formData', formData, formData.get('code'));
  //   // setCode(formData.get('code'));
  //   const codeFromForm = formData.get('code');

  //   // try {
  //     const response = await fetch(`${API_BASE_URL}/auth/login/code`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ code: codeFromForm }),
  //       credentials: 'include', // w sumie nie wiem czy jest sens bo nie chodzi o to by tu przeslac cookie, ale by serwer po utworzeniu je odebral
  //       redirect: 'manual',
  //     });

  //     // console.log(response.status, response.redirected, response.ok, response.url);
  //     console.log(response);
  //     console.log('response.redirected', response.redirected);
  //     console.log('response.ok', response.ok);
  //     console.log('response.url', response.url);
  //     if (response.redirected) {
  //       navigate(response.url);
  //       // window.location.href = response.url;
  //     } else if (response.ok) {
  //               navigate(response.url);

  //       // window.location.href = response.url;
  //     } else {
  //       setError('Invalid code. Please try again.');
  //     }
  //   // } catch (e) {
  //   //   setError('An error occurred. Please try again later.');
  //   // }
  // };
  // TODO: add isLoading state

  const textFieldRef = useRef(null);
  // const [initFocusChanged, setInitFocusChanged] = useState(false);

  // const handleBrowserAutoFocus = () => {
  //   if (initFocusChanged) {
  //     return;
  //   }
  //   setInitFocusChanged(true);
  // };

  // useEffect(() => {
  //   textFieldRef.current.blur();
  // }, [initFocusChanged]);

  return (
    <Box
      as="form"
      method="post"
      action={`${API_BASE_URL}/auth/login/code`}
      // onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
      <InputMask
        mask="****-****-****"
        // value={code}
        disabled={false}
        maskChar="_"
        onChange={(e) => setCode(e.target.value)}
        // onFocus={handleBrowserAutoFocus}
      >
        {() => (
          <TextField
            name="code"
            label="Access Code"
            InputLabelProps={{
              sx: {
                textAlign: code ? 'left' : 'center',
                width: '100%',
                transformOrigin: code ? 'left' : 'center',
                top: code ? '-3px' : '0',
              },
            }}
            size="small"
            inputRef={textFieldRef}
            sx={{
              width: '100%',
              '& input': { fontFamily: 'monospace', textTransform: 'uppercase', textAlign: 'center' },
            }}
          />
        )}
      </InputMask>

      <Button type="submit" variant="outlined">
        Go
      </Button>
    </Box>
  );
}
