import { Box, Container, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../components/Logo';
import { RoleButton } from '../../components/RoleButton';
import { UserContext } from '../../contexts/UserContext';
import { ROUTES } from '../../routes';
// auth VS login url
const useMessages = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    switch (message) {
      case 'code-login-failed':
        enqueueSnackbar('Access code login failed. Please try again.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        navigate('/', { replace: true });
        break;
      default:
      // Do nothing
    }
  }, [message]);
};

export function HomePage() {
  const user = useContext(UserContext);
  useMessages();

  if (user) {
    if (user.role === 'NOT_SET') {
      return <Navigate replace to={ROUTES.PROFILE_ROLE_SELECTION} />;
    }

    if (user.isTeacher) {
      return <Navigate replace to={ROUTES.DASHBOARD_HOME} />;
    }

    if (user.isAdmin) {
      return <Navigate replace to={ROUTES.ADMIN_USERS} />;
    }

    return <Navigate replace to={ROUTES.STUDENT_DASHBOARD} />;
  }

return <Navigate replace to='/sign-in?role=teacher' />;

  // eslint-disable-next-line no-unreachable
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: {
            xs: '100%',
            sm: '40vh',
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Logo imgStyle={{ width: '100%', maxWidth: '300px' }} />
      </Box>
      <Typography variant="h4" component="h2" sx={{ color: 'GrayText', fontSize: '1.2rem' }}>
        I will continue as:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: '28vh',
        }}
      >
        <RoleButton text="A Teacher" link="/sign-in" searchParams={{ role: 'teacher' }} />
        <RoleButton text="A Student" link="/sign-in" searchParams={{ role: 'student' }} />
      </Box>
    </Container>
  );
}
