import { Box, Button, TableBody, TableHead, TableSortLabel } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { BooleanValue } from '../../../components/BooleanValue';
import { Table, Td, Th, Tr } from '../../../components/layout/Table';
import { UserContext } from '../../../contexts/UserContext';
import { isOutlineAndWritingAssignment } from '../../../helpers/assignment';
import { ROUTES } from '../../../routes';
import { shorten } from '../../../utils/text';

export function DemoAssignmentsList({
  assignments,
  caption = 'Demo Assignments',
  ariaLabel = 'List of demo assignments',
  sx,
}) {
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('desc');

  const user = useContext(UserContext);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedAssignments = useMemo(() =>
    [...assignments].sort(
      (a, b) => {
        if (order === 'asc') {
          return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1;
        }
        return a[orderBy].toLowerCase() > b[orderBy].toLowerCase() ? -1 : 1;
      },
      [assignments, orderBy, order]
    )
  );

  return (
    <Table ariaLabel={ariaLabel} caption={caption} captionVariant="h5" sx={sx}>
      <TableHead>
        <Tr>
          <Th align="left" width="45%">
            <TableSortLabel active={orderBy === 'title'} direction={order} onClick={() => handleRequestSort('title')}>
              Title
            </TableSortLabel>
          </Th>
          <Th align="left" width="20%">
            Template
          </Th>
          <Th align="center" width="20%">
            Outline
          </Th>
          <Th align="center" width="15%">
            Try Demo
          </Th>
        </Tr>
      </TableHead>
      <TableBody>
        {sortedAssignments.map((assignment) => (
          <Tr key={assignment.id}>
            <Td>
              <Button
                component={Link}
                fullWidth
                sx={{
                  justifyContent: 'start',
                  textAlign: 'left',
                  cursor: 'pointer',
                  textTransform: 'none',
                }}
                to={ROUTES.showAssignment(assignment.id)}
                variant="text"
              >
                {assignment.title}
              </Button>
            </Td>
            <Td align="left">
              {assignment.template ? (
                <Box sx={{ color: 'text.secondary' }}>{shorten(assignment.template.name, 35)}</Box>
              ) : (
                <BooleanValue value={false} align="left" />
              )}
            </Td>
            <Td align="center">
              <BooleanValue value={isOutlineAndWritingAssignment(assignment.type)} />
            </Td>
            <Td align="center">
              <Button
                component={Link}
                to={ROUTES.writeAssignment(assignment.id, user.id)}
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  minWidth: 160,
                  textTransform: 'none',
                  boxShadow: 1,
                }}
              >
                Write As A Student
              </Button>
            </Td>
          </Tr>
        ))}
      </TableBody>
    </Table>
  );
}
