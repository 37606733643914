import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

export function NavMenuItem({ 
  title, 
  icon: Icon, 
  to, 
  onClick, 
  selected = false, 
  disableHoverListener = false,
  iconSize = "medium",
  placement = "right",
  open = false,
  iconColor
}) {
  const isExpanded = disableHoverListener;
  
  const content = (
    <ListItem disablePadding sx={{ display: 'block', mb: 0.5 }}>
      <ListItemButton
        sx={{ 
          minHeight: 48,
          display: 'flex', 
          flexDirection: isExpanded ? 'row' : 'column', 
          alignItems: 'center',
          justifyContent: isExpanded ? 'flex-start' : 'center',
          px: 2.5,
          py: isExpanded ? 1 : 1,
          borderRadius: 1,
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
          },
          '&:hover': {
            backgroundColor: selected ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.02)',
          }
        }}
        component={to ? Link : undefined}
        to={to}
        onClick={onClick}
        selected={selected}
      >
        <ListItemIcon 
          sx={{ 
            minWidth: 0, 
            mr: isExpanded ? 2 : 0, 
            mb: isExpanded ? 0 : 0,
            justifyContent: 'center',
            color: selected ? 'inherit' : (iconColor || 'primary.main')
          }}
        >
          <Icon fontSize={iconSize} />
        </ListItemIcon>
        <ListItemText 
          primary={title} 
          sx={{ 
            opacity: isExpanded ? 1 : 0,
            display: { xs: isExpanded ? 'block' : 'none' }
          }}
          primaryTypographyProps={{ 
            fontSize: '0.85rem',
            textAlign: isExpanded ? 'left' : 'center',
            noWrap: true,
            fontWeight: 500
          }} 
        />
      </ListItemButton>
    </ListItem>
  );

  if (!title || isExpanded) return content;

  return (
    <Tooltip title={title} placement={placement} disableHoverListener={disableHoverListener}>
      {content}
    </Tooltip>
  );
} 