import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_BASE_URL } from '../../../config';

const getDemoAssignments = async () => {
  const response = await axios.get(`${API_BASE_URL}/api/assignments/demo`, {
    withCredentials: true,
  });
  return response.data.assignments;
};

export const useDemoAssignments = () => useQuery(['demoAssignments'], getDemoAssignments, {
  retry: false,
});
