import { Stack, Switch, FormControlLabel, IconButton, Popover, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import { useLanguageSettings } from '../contexts/LanguageSettingsContext';
import { TextToSpeech } from './pen-gwen/TextToSpeech';

const languages = [
  { displayValue: '🇺🇸 English', code: 'en-US' },
  { displayValue: '🇪🇸 Spanish', code: 'es-ES' },
  { displayValue: '🇨🇳 Chinese (Mandarin)', code: 'zh-CN' },
  // { displayValue: '🇭🇰 Chinese (Cantonese)', code: 'zh-HK' },
  // { displayValue: '🇻🇳 Vietnamese', code: 'vi-VN' },
  { displayValue: '🇸🇦 Arabic', code: 'ar-SA' },
  // { displayValue: '🇵🇭 Tagalog', code: 'tl-PH' },
  { displayValue: '🇰🇷 Korean', code: 'ko-KR' },
  // { displayValue: '🇭🇹 Haitian Creole', code: 'ht-HT' },
  // { displayValue: '🇵🇹 Portuguese', code: 'pt-PT' },
  { displayValue: '🇷🇺 Russian', code: 'ru-RU' },
  // { displayValue: '🇵🇰 Urdu', code: 'ur-PK' },
  // { displayValue: '🇵🇱 Polish', code: 'pl-PL' },
];

export function LanguageSettings({ textToSpeech = true, promptResult }) {
  const { languageSettings, updateLanguageSettings } = useLanguageSettings();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={0}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          borderRadius: 2,
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        <IconButton size="small" disableRipple>
          <SettingsIcon />
        </IconButton>
        <Typography variant="subtitle2">Language Settings</Typography>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction="column" gap={1} sx={{ p: 2 }}>
          <select
            value={languageSettings.language}
            onChange={(e) => updateLanguageSettings({ language: e.target.value })}
            style={{
              padding: '2px 5px',
              fontSize: '14px',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.displayValue}
              </option>
            ))}
          </select>
          {textToSpeech && <TextToSpeech text={promptResult} language={languageSettings.language} />}
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={languageSettings.simpleLanguage}
                onChange={(e) => updateLanguageSettings({ simpleLanguage: e.target.checked })}
              />
            }
            label="Simple Language"
          />
        </Stack>
      </Popover>
    </>
  );
}
