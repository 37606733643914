import axios from 'axios';
import { Container, Grid, Typography, Paper, Box, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Breadcrumb } from '../../components/layout/Breadcrumb';
import { PricingTier } from './components/PricingTier';
import { API_BASE_URL } from '../../config';

const tiers = [
  {
    name: 'Essentials',
    price: 'Free',
    // price: '$4.99',
    // period: 'per month',
    features: [
      'Up to 3 students',
      'Up to 500 monthly queries to Gwen AI',
    ],
    buttonText: 'Select Plan',
  },
  {
    name: 'Basic',
    price: '$8.99',
    period: 'per month',
    popular: true,
    features: [
      '1 Instructor Account',
      '1 Month free trial',
      'Up to 50 students',
      'Up to 3000 monthly queries to Gwen AI',
      'Writing Replay',
      'Copy-Paste Protection',
      'Grading Analysis',
      'Email Support',
    ],
    buttonText: 'Start Free Trial',
  },
  {
    name: 'Premium',
    price: '$18.99',
    period: 'per month',
    features: [
      '1 Instructor Account',
      '1 Month free trial',
      'Up to 150 students',
      'Up to 10000 monthly queries to Gwen AI',
      'Writing Replay',
      'Copy-Paste Protection', 
      'Grading Analysis',
      'Email Support',
      'Phone Support',
    ],
    buttonText: 'Start Free Trial',
  },
  {
    name: 'Institution',
    price: 'Contact Us',
    features: [
      'School and District-level Analytics',
      'Multiple Teachers, unlimited classes',
      'Pricing per student',
      'Integration with Canva, Schoology, or Google Classroom',
      'Ongoing support and training',
    ],
    buttonText: 'Contact Sales',
  },
];

export function BillingPage() {
  const [selectedTier, setSelectedTier] = useState('basic');
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const getButtonProps = (tier) => {
    if (subscription && tier.name === subscription.plan) {
      return {
        buttonText: 'Current Plan',
        disabled: true
      };
    }
    // Show "Available after trial" for all plans except current and Contact Sales during trial
    if (subscription?.trialEndsAt && 
        new Date(subscription.trialEndsAt) > new Date() && 
        tier.buttonText !== 'Contact Sales' &&
        tier.name !== subscription.plan) {
      return {
        buttonText: 'Available After Trial',
        disabled: true,
        isMessage: true
      };
    }
    return {
      buttonText: tier.buttonText,
      disabled: updating
    };
  };

  const loadSubscription = async () => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/api/billing/subscription`, {
        withCredentials: true
      });
      setSubscription(data);
      if (data.plan) {
        setSelectedTier(data.plan.toLowerCase() === 'essentials' ? 'Basic' : data.plan);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to load subscription data', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleStartTrial = async (plan) => {
    try {
      setUpdating(true);
      await axios.put(`${API_BASE_URL}/api/billing/subscription`, {
        action_type: 'start_trial',
        plan
      }, {
        withCredentials: true
      });
      await loadSubscription();  // Reload subscription data
      enqueueSnackbar('Trial started successfully', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        error.response?.data?.error || 'Failed to start trial',
        { variant: 'error' }
      );
    } finally {
      setUpdating(false);
    }
  };

  const handleContactSales = () => {
    window.open('https://www.wordpath.com/contact', '_blank');
  };

  useEffect(() => {
    loadSubscription();
  }, []);

  return (
    <Container>
      <Breadcrumb />
      {subscription && (
        <Box
          sx={{
            mb: 4,
            py: 2,
            px: 3,
            borderRadius: 2,
            backgroundColor: 'primary.main',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ opacity: 0.9 }}>
              Current Plan
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {subscription.plan}
            </Typography>
          </Box>
          {subscription.trialEndsAt && new Date(subscription.trialEndsAt) > new Date() && (
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle2" sx={{ opacity: 0.9 }}>
                Trial Ends
              </Typography>
              <Typography variant="body1">
                {new Date(subscription.trialEndsAt).toLocaleDateString()}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h4" color="text.primary" gutterBottom>
          Choose Your Plan
        </Typography>
        {!subscription && (
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            You currently have no subscription. Select a plan to get started.
          </Typography>
        )}
        <Typography variant="body1" color="text.secondary">
          Select the plan that best fits your needs
        </Typography>
      </Box>

      {loading ? (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography>Loading subscription details...</Typography>
        </Box>
      ) : (
        <Grid container spacing={4} justifyContent="center">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.name}
              xs={12}
              sm={6}
              md={3}
            >
              <PricingTier
                tier={{
                  ...tier,
                  buttonText: getButtonProps(tier).buttonText
                }}
                selected={selectedTier.toLowerCase() === tier.name.toLowerCase()}
                onSelect={() => {
                  if (tier.buttonText === 'Contact Sales') {
                    handleContactSales();
                  } else if (tier.buttonText === 'Start Free Trial') {
                    handleStartTrial(tier.name);
                  }
                  setSelectedTier(tier.name);
                }}
                buttonVariant={selectedTier.toLowerCase() === tier.name.toLowerCase() ? 'contained' : 'outlined'}
                disabled={getButtonProps(tier).disabled}
                isMessage={getButtonProps(tier).isMessage}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
} 
