import { Container, Typography, Paper } from '@mui/material';
import { Breadcrumb } from '../../components/layout/Breadcrumb';

export function HelpPage() {
  return (
    <Container>
      <Breadcrumb />
      <Paper sx={{ padding: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Help Center
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to the Help Center. Here you&apos;ll find resources and guidance to help you make the most of our platform.
        </Typography>
      </Paper>
    </Container>
  );
} 