import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import { ROUTES } from '../routes';

// TODO allow role - chyba nie jest potrzebne
export default function ProtectedRoute({ redirectPath = '/', allowNoRole = false }) {
  const user = useContext(UserContext);

  if (!user) {
    console.log('Użytkownik nie jest zalogowany, przekierowuję na:', redirectPath);
    window.location.href = redirectPath;
    return null;
  }

  if (user.role === 'NOT_SET' && !allowNoRole) {
    window.location.href = ROUTES.PROFILE_ROLE_SELECTION;
    return null;
  }

  return <Outlet />;
}
