import { Paper, Typography, Button, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export function PricingTier({ tier, selected, onSelect, buttonVariant, disabled, isMessage }) {
  return (
    <Paper
      onClick={onSelect}
      elevation={selected ? 8 : 1}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        transition: 'all 0.3s ease-in-out',
        border: (theme) => `2px solid ${selected ? theme.palette.primary.main : 'transparent'}`,
        '&:hover': {
          transform: 'translateY(-4px)',
          cursor: 'pointer',
        },
      }}
    >
      {tier.popular && (
        <Box
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            backgroundColor: 'primary.main',
            color: 'white',
            px: 2,
            py: 0.5,
            borderRadius: 1,
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}
        >
          POPULAR
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <Typography
          component="h3"
          variant="h6"
          color="text.primary"
          gutterBottom
          sx={{ fontWeight: selected ? 'bold' : 'normal' }}
        >
          {tier.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 2 }}>
          <Typography component="h4" variant="h4" color="text.primary">
            {tier.price}
          </Typography>
          {tier.period && (
            <Typography variant="subtitle1" color="text.secondary" sx={{ ml: 1 }}>
              {tier.period}
            </Typography>
          )}
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        {tier.features.map((feature) => (
          <Box
            key={feature}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1,
            }}
          >
            <CheckIcon sx={{ mr: 1, fontSize: 20, color: 'primary.main' }} />
            <Typography variant="body2" color="text.secondary">
              {feature}
            </Typography>
          </Box>
        ))}
      </Box>

      {tier.buttonText === 'Current Plan' || isMessage ? (
        <Box
          sx={{
            mt: 4,
            py: 1,
            textAlign: 'center',
            backgroundColor: tier.buttonText === 'Current Plan' ? 'success.main' : 'info.main',
            color: 'white',
            borderRadius: 1,
            fontSize: '0.875rem',
            fontWeight: 'bold',
            textTransform: 'none'
          }}
        >
          {tier.buttonText}
        </Box>
      ) : (
        <Button
          fullWidth
          variant={buttonVariant}
          sx={{ mt: 4, textTransform: 'none' }}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            onSelect();
          }}
        >
          {tier.buttonText}
        </Button>
      )}
    </Paper>
  );
} 
