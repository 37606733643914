import React, { useContext, useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Button, Paper, Divider, useTheme } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import axios from 'axios';

import { API_BASE_URL } from '../../../config';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { UserContext } from '../../../contexts/UserContext';
import { ROUTES } from '../../../routes';

const roles = [
  {
    id: 'TEACHER',
    title: 'Teacher',
    description:
      'Encourage creativity, leverage AI for feedback, track progress and guide them toward stronger writing',
    icon: SchoolIcon,
    category: 'educator',
  },
  {
    id: 'PARENT',
    title: 'Parent',
    description:
      "Support your child's learning, track their progress, and gain the superpowers of a great teacher with AI by your side",
    icon: FamilyRestroomIcon,
    category: 'educator',
  },
  {
    id: 'STUDENT',
    title: 'Student',
    description: 'Learn to express your thoughts clearly, sharpen your writing, and communicate with confidence',
    icon: AutoStoriesIcon,
    category: 'student',
  },
  {
    id: 'ADMINISTRATOR',
    title: 'School Administrator',
    description: 'Manage teachers and students, track progress, and review reports to guide growth',
    icon: AdminPanelSettingsIcon,
    category: 'admin',
  },
];

// Ultra-compact role selection card
const RoleButton = ({ role, isSelected, handleRoleSelect, theme, equalHeight, verticalLayout, disabled }) => {
  // Calculate sizes based on layout type
  let iconSize;
  let padding;
  let iconBoxWidth;
  let iconBoxHeight;
  let fontSize;
  let lineHeight;
  let textFontSize;
  let buttonPadding;
  let marginBottom;
  let iconPadding;
  let iconMarginBottom;
  let boxMarginBottom;
  let typographyMarginBottom;
  let buttonSize;
  let buttonMinWidth;
  let buttonPaddingX;
  
  if (verticalLayout) {
    if (role.category === 'admin') {
      // Making admin column same size as student column
      iconSize = '2rem';
      padding = 3;
      iconBoxWidth = 60;
      iconBoxHeight = 60;
      fontSize = '1.1rem';
      lineHeight = 1.4;
      textFontSize = '0.9rem';
      buttonPadding = 0.8;
      marginBottom = 1;
      iconPadding = 1.5;
      iconMarginBottom = 2;
      boxMarginBottom = 2;
      typographyMarginBottom = 1;
      buttonSize = "medium";
      buttonMinWidth = 100;
      buttonPaddingX = 2;
    } else {
      // Regular sizes for student column
      iconSize = '2rem';
      padding = 3;
      iconBoxWidth = 60;
      iconBoxHeight = 60;
      fontSize = '1.1rem';
      lineHeight = 1.4;
      textFontSize = '0.9rem';
      buttonPadding = 0.8;
      marginBottom = 1;
      iconPadding = 1.5;
      iconMarginBottom = 2;
      boxMarginBottom = 2;
      typographyMarginBottom = 1;
      buttonSize = "medium";
      buttonMinWidth = 100;
      buttonPaddingX = 2;
    }
  } else if (role.category === 'educator') {
    iconSize = '1.4rem';
    padding = 2;
    iconBoxWidth = 42;
    iconBoxHeight = 42;
    fontSize = '1.05rem';
    lineHeight = 1.3;
    textFontSize = '0.9rem';
    buttonPadding = 0.7;
    marginBottom = 1.5;
    iconPadding = 0.8;
    iconMarginBottom = 0;
    boxMarginBottom = 0;
    typographyMarginBottom = 0;
    buttonSize = "small";
    buttonMinWidth = 72;
    buttonPaddingX = 1;
  } else {
    iconSize = '1.2rem';
    padding = 1.5;
    iconBoxWidth = 36;
    iconBoxHeight = 36;
    fontSize = '0.875rem';
    lineHeight = 1.2;
    textFontSize = '0.75rem';
    buttonPadding = 0.5;
    marginBottom = 1;
    iconPadding = 0.8;
    iconMarginBottom = 0;
    boxMarginBottom = 0;
    typographyMarginBottom = 0;
    buttonSize = "small";
    buttonMinWidth = 72;
    buttonPaddingX = 1;
  }
  
  // Helper function to determine hover transform
  const getHoverTransform = () => {
    if (disabled) {
      return 'none';
    }
    if (verticalLayout) {
      return 'translateY(-3px)';
    }
    return 'translateX(3px)';
  };
  
  return (
    <Paper
      elevation={isSelected ? 4 : 1}
      onClick={() => !disabled && handleRoleSelect(role.id)}
      sx={{
        display: 'flex',
        flexDirection: verticalLayout ? 'column' : 'row',
        alignItems: verticalLayout ? 'center' : 'flex-start',
        p: padding,
        mb: marginBottom,
        borderRadius: 2,
        cursor: disabled ? 'default' : 'pointer',
        borderLeft: verticalLayout ? 'none' : '4px solid transparent',
        borderTop: 'none',
        bgcolor: isSelected ? 'rgba(25, 118, 210, 0.05)' : 'background.paper',
        transition: 'all 0.2s ease',
        flex: equalHeight ? 1 : 'none',
        height: verticalLayout ? '100%' : 'auto',
        opacity: disabled ? 0.6 : 1,
        filter: disabled ? 'grayscale(0.7)' : 'none',
        '&:hover': {
          bgcolor: isSelected ? 'rgba(25, 118, 210, 0.07)' : 'rgba(0, 0, 0, 0.02)',
          transform: getHoverTransform()
        }
      }}
    >
      {/* Pierwsza kolumna - ikona i nazwa pod nią */}
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: verticalLayout ? '100%' : '80px',
        mr: verticalLayout ? 0 : 2
      }}>
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            bgcolor: isSelected ? 'primary.main' : theme.palette.grey[100],
            color: isSelected ? 'white' : theme.palette.primary.main,
            p: iconPadding,
            mb: 1,
            width: iconBoxWidth,
            height: iconBoxHeight,
            flexShrink: 0
          }}
        >
          <role.icon sx={{ fontSize: iconSize }} />
        </Box>
        
        <Typography 
          variant="subtitle2" 
          fontWeight={isSelected ? 600 : 500} 
          align="center"
          sx={{ fontSize }}
        >
          {role.title}
        </Typography>
        
        {role.id === 'TEACHER' && (
          <Typography 
            variant="subtitle2" 
            fontWeight={isSelected ? 600 : 500} 
            align="center"
            sx={{ 
              fontSize,
              color: theme.palette.text.primary,
              mt: 0.3
            }}
          >
            Tutor
          </Typography>
        )}
      </Box>
      
      {/* Druga kolumna - opis i przycisk */}
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 0
      }}>
        <Typography 
          variant="caption" 
          color="text.secondary" 
          sx={{ 
            display: 'block',
            mb: verticalLayout ? boxMarginBottom : 1.5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight,
            fontSize: textFontSize,
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            textAlign: verticalLayout ? 'center' : 'left',
          }}
        >
          {role.description}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: verticalLayout ? 'center' : 'center',
          mt: 'auto',
          position: 'relative'
        }}>
          {disabled && (
            <Typography 
              variant="caption" 
              sx={{ 
                color: theme.palette.text.secondary,
                fontStyle: 'italic',
                fontSize: '0.7rem',
                position: 'absolute',
                top: -15,
                left: '50%',
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                px: 1,
                borderRadius: 1
              }}
            >
              Coming soon
            </Typography>
          )}
          <Button 
            variant={isSelected ? "contained" : "outlined"} 
            size={buttonSize}
            disabled={disabled}
            sx={{ 
              minWidth: buttonMinWidth, 
              px: buttonPaddingX, 
              flexShrink: 0,
              py: buttonPadding,
              fontSize: 'inherit'
            }}
          >
            {isSelected ? "Selected" : "Select"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

// Role category component
const RoleCategory = ({ title, rolesList, selectedRole, handleRoleSelect, theme, equalHeight, verticalLayout, sx, disabled }) => (
  <Box sx={{ 
    ...sx,
    opacity: disabled ? 0.6 : 1,
    filter: disabled ? 'grayscale(0.7)' : 'none',
    position: 'relative'
  }}>
    <Typography 
      variant="subtitle1" 
      color={disabled ? 'text.secondary' : 'primary.main'}
      fontWeight={600} 
      sx={{ 
        mb: 2, 
        textAlign: 'center',
      }}
    >
      {title}
    </Typography>
    
    <Box sx={{ 
      height: equalHeight ? '100%' : 'auto', 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: rolesList.length === 1 ? 'center' : 'flex-start',
      position: 'relative'
    }}>
      {rolesList.map((role) => (
        <RoleButton
          key={role.id}
          role={role}
          isSelected={selectedRole === role.id}
          handleRoleSelect={handleRoleSelect}
          theme={theme}
          equalHeight={equalHeight}
          verticalLayout={verticalLayout}
          disabled={disabled}
        />
      ))}
    </Box>
  </Box>
);

export function Page() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedRole, setSelectedRole] = useState('TEACHER');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [animate, setAnimate] = useState(false);
  
  // Predefiniowana lista ikon
  const backgroundIcons = [
    { id: 'school1', Icon: SchoolIcon },
    { id: 'person1', Icon: PersonIcon },
    { id: 'family1', Icon: FamilyRestroomIcon },
    { id: 'book1', Icon: AutoStoriesIcon },
    { id: 'admin1', Icon: AdminPanelSettingsIcon },
    { id: 'school2', Icon: SchoolIcon },
    { id: 'person2', Icon: PersonIcon },
    { id: 'family2', Icon: FamilyRestroomIcon },
    { id: 'book2', Icon: AutoStoriesIcon },
    { id: 'admin2', Icon: AdminPanelSettingsIcon },
    { id: 'school3', Icon: SchoolIcon },
    { id: 'person3', Icon: PersonIcon },
    { id: 'family3', Icon: FamilyRestroomIcon },
    { id: 'book3', Icon: AutoStoriesIcon },
    { id: 'admin3', Icon: AdminPanelSettingsIcon },
    { id: 'school4', Icon: SchoolIcon },
    { id: 'person4', Icon: PersonIcon },
    { id: 'family4', Icon: FamilyRestroomIcon },
    { id: 'book4', Icon: AutoStoriesIcon },
    { id: 'admin4', Icon: AdminPanelSettingsIcon },
  ];
  
  useEffect(() => {
    // Trigger animation after component mount
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  
  if (!user) {
    return <Navigate replace to={ROUTES.SIGN_IN} />;
  }

  const handleRoleSelect = (roleId) => {
    setSelectedRole(roleId);
  };

  const handleSubmit = async () => {
    if (!selectedRole) return;

    setIsSubmitting(true);
    try {
      await axios.post(
        `${API_BASE_URL}/api/users/role`,
        { role: selectedRole },
        { withCredentials: true }
      );

      navigate(ROUTES.HOME);
    } catch (error) {
      console.error('Error setting user role:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return <LoadingIndicator />;
  }

  // Group roles by category
  const educatorRoles = roles.filter(role => role.category === 'educator');
  const adminRoles = roles.filter(role => role.category === 'admin');
  const studentRoles = roles.filter(role => role.category === 'student');

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, borderRadius: 2 }}>
        <Box
          mb={5}
          textAlign="center"
          sx={{
            position: 'relative',
            borderRadius: 3,
            overflow: 'hidden',
            p: 4,
            background:
              theme.palette.mode === 'light'
                ? 'linear-gradient(135deg, rgba(21,101,192,0.05) 0%, rgba(30,136,229,0.12) 100%)'
                : 'linear-gradient(135deg, rgba(13,71,161,0.15) 0%, rgba(25,118,210,0.2) 100%)',
            boxShadow: '0 3px 10px rgba(0,0,0,0.04)',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '100%',
              zIndex: 0,
              overflow: 'hidden',
              opacity: 0.4,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 6,
                top: animate ? -10 : 0,
                left: animate ? -10 : 0,
                transition: 'top 25s linear, left 25s linear',
                opacity: 0.5,
                transform: 'rotate(10deg)',
              }}
            >
              {backgroundIcons.map(({ id, Icon }) => (
                <Box
                  key={id}
                  component={Icon}
                  sx={{
                    fontSize: `${1 + Math.random() * 2}rem`,
                    color: theme.palette.mode === 'light' ? '#0d47a1' : '#42a5f5',
                    opacity: 0.2 + Math.random() * 0.3,
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <Typography
              variant="h3"
              component="h1"
              fontWeight={700}
              mb={2}
              sx={{
                textShadow: '0 2px 10px rgba(255,255,255,0.5)',
                color: theme.palette.mode === 'light' ? '#1565c0' : '#2196f3',
                transform: animate ? 'scale(1)' : 'scale(0.95)',
                opacity: animate ? 1 : 0,
                transition: 'transform 0.5s ease, opacity 0.5s ease',
              }}
            >
              Choose Your Role
            </Typography>

            <Typography
              variant="body1"
              color="text.secondary"
              sx={{
                maxWidth: '450px',
                mx: 'auto',
                opacity: animate ? 1 : 0,
                transition: 'opacity 0.5s ease 0.3s',
                fontWeight: 500,
              }}
            >
              Select the role that best describes you, and we&apos;ll tailor your experience
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: 3, md: 4 },
          }}
        >
          <Box sx={{ flex: '1 1 0', minWidth: 0 }}>
            <RoleCategory
              title="I Teach and Support Learners"
              rolesList={educatorRoles}
              selectedRole={selectedRole}
              handleRoleSelect={handleRoleSelect}
              theme={theme}
            />
          </Box>

          <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
          <Divider sx={{ display: { xs: 'block', md: 'none' } }} />

          <Box
            sx={{
              flex: '0.7 1 0',
              minWidth: 0,
              display: 'flex',
              alignItems: 'stretch',
              height: { md: '100%' },
            }}
          >
            <RoleCategory
              title="I'm Here to Grow and Learn"
              rolesList={studentRoles}
              selectedRole={selectedRole}
              handleRoleSelect={handleRoleSelect}
              theme={theme}
              equalHeight
              verticalLayout
            />
          </Box>

          <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
          <Divider sx={{ display: { xs: 'block', md: 'none' } }} />

          <Box
            sx={{
              flex: '0.7 1 0',
              minWidth: 0,
              display: 'flex',
              alignItems: 'stretch',
              height: { md: '100%' },
              position: 'relative',
            }}
          >
            <RoleCategory
              title="I Organize and Manage Education"
              rolesList={adminRoles}
              selectedRole={selectedRole}
              handleRoleSelect={() => {}}
              theme={theme}
              equalHeight
              verticalLayout
              disabled
            />
          </Box>
        </Box>

        <Box mt={4} pt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            disabled={!selectedRole || isSubmitting}
            onClick={handleSubmit}
            sx={{ px: 4, py: 1 }}
          >
            Continue
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}